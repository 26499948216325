body, html {
    font-family: Arial, Helvetica, sans-serif;
    background-color: #f0f0f0;
    margin: 0;
    padding: 0;
    height: 100%;
}

.header {
    background-color: #007bff;
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    margin: 0;
}

.ticket-info-container {
    background-color: #fff;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-height: 100vh;
    overflow-y: auto;
}

.event-info {
    display: flex;
    align-items: center;
}

.event-image {
    width: 100%;
    max-width: 180px;
    margin-right: 20px;
    object-fit: cover;
}

.event-content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
}

.ticket-info-header {
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    text-align: center;
    margin: 0 0 10px 0;
    flex: 1;
    width: 100%;
    box-sizing: border-box;
}

.event-details {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.event-square {
    width: 150px;
    height: 150px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.event-square.zero-stock {
    background-color: #d3d3d3;
    color: #888;
}

.event-time {
    font-size: 50px;
    border-bottom: 1px solid #aaa;
    width: 100%;
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.event-ticket {
    font-size: 30px;
    padding: 10px 0;
}

.no-events-message {
    font-size: 50px;
    color: #d9534f;
    font-weight: bold;
    padding: 10px;
    text-align: center;
    width: 100%;
    height: 150px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 20px;
}


.progress-bar-container {
    width: 100%;
    background-color: #ddd;
}

.progress-bar {
    height: 10px;
    background-color: #007bff;
    transition: width 1s linear;
}

/* スマホ向けのスタイル */
@media (max-width: 768px) {
    .event-details {
        width: 100%; /* ticket-info-headerと同じ幅 */
        box-sizing: border-box; /* paddingとborderをwidthに含める */
        padding: 0 10px; /* ticket-info-headerの左右のpaddingに合わせる */
        margin: 0 auto; /* 中央寄せ */
    }

    .event-image {
        display: none; /* スマートフォン表示ではevent-imageを非表示に */
    }

    .event-time {
        float: left; /* 左側に配置 */
        width: 50%; /* 幅を半分に設定 */
        box-sizing: border-box; /* paddingとborderをwidthに含める */
        padding-right: 10px; /* 右側に余白を設ける */
    }

    .event-ticket {
        float: right; /* 右側に配置 */
        width: 50%; /* 幅を半分に設定 */
        box-sizing: border-box; /* paddingとborderをwidthに含める */
        padding-left: 10px; /* 左側に余白を設ける */
    }

    .event-image {
        position: absolute;
        top: 10px;       /* 上からの位置を調整 */
        right: 10px;     /* 右からの位置を調整 */
        width: 50px;     /* 必要に応じてサイズを調整 */
        height: 50px;    /* 必要に応じてサイズを調整 */
    }

    .event-square {
        display: block;
        width: 100%;     /* 幅を最大に */
        height: 50px;    /* 高さを調整 */
        line-height: 50px; /* テキストを中央に配置 */
        text-align: center; /* テキストを中央に */
        clear: both; /* floatをクリア */
    }
}